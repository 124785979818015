import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Policies from "./policies";
import HomePage from "./home";
import "./home/HomePage.css";
import "./App.css";
import { fetchAndroidBuild } from "./Supabase";
import { colors } from "./constants/Colors";

const App = () => {
  const [androidBuild, setAndroidBuild] = useState<string>("");
  const [iosBuild, setIosBuild] = useState<string>("");

  useEffect(() => {
    const getBuilds = async () => {
      const { data: aUrl } = await fetchAndroidBuild();
      setAndroidBuild(aUrl);
      const { data: iUrl } = await fetchAndroidBuild();
      setIosBuild(iUrl);
    };

    getBuilds();
  }, []);

  return (
    <Router>
      <div
        style={{
          width: "100vm",
          maxWidth: "100%",
        }}
      >
        {/* Header Section */}
        <header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 20px",
            borderBottom: "1px solid #5E17EB",
            height: 80,
            width: "100vm",
            maxWidth: "100%",
            position: "sticky",
            top: 0,
            backgroundColor: "#1E1E1E",
            zIndex: 1000,
          }}
        >
          <Link
            to="/"
            style={{
              color: colors.primary,
              fontWeight: "bold",
            }}
          >
            <h1 style={{ fontSize: "1.5rem", color: colors.primary }}>TTM</h1>
          </Link>
          <nav
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              marginLeft: 5,
            }}
          >
            <a href={iosBuild} className="hero-button">
              iOS
            </a>
            <a href={androidBuild} className="hero-button">
              Android
            </a>
          </nav>
        </header>

        {/* Routes Section */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/policies" element={<Policies />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
