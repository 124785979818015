export const colors = {
  // Primary brand colors
  primary: "#A786FF", // Soft lavender purple for brand identity
  secondary: "#444444", // Dark gray to contrast subtly with black

  // Background colors
  background: "#000000", // Black background for a sleek design
  cardBackground: "#1A1A1A", // Slightly lighter black for depth in cards or containers

  // Text colors
  textPrimary: "#FFFFFF", // Crisp white for primary text
  textSecondary: "#BBBBBB", // Light gray for secondary text for better contrast

  // Accent colors
  accent: "#5E5E5E", // Neutral gray for subtle accents or highlights

  // Error and status colors
  error: "#FF5C5C", // Muted red for errors
  success: "#4CAF50", // Soft green for success messages
  warning: "#FFB74D", // Warm orange for warnings
  blue: "#4A90E2", // Clear blue for call-to-actions or links

  // Button colors
  buttonPrimary: "#6B49E6", // Vibrant purple for primary buttons
  buttonSecondary: "#666666", // Medium gray for secondary buttons with good contrast on black

  // Link and interaction colors
  link: "#AB82FF", // Bright purple for links to maintain brand identity
  hover: "#9370DB", // Slightly lighter purple for hover states

  // Shadows and borders
  shadow: "#1F1F1F", // Subtle dark shadow for depth
  border: "#3A3A3A", // Dark gray for borders to complement the black background
};
