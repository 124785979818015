import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://api-ttm.globemedia.me";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzM0MDc2ODAwLAogICJleHAiOiAxODkxODQzMjAwCn0.fxBkE_p18GwNh0zXP2BNa5hxaSEXsG13_uNzTaA8k8c";

// Initialize Supabase client
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const fetchParallaxContent = async () => {
  const { data, error } = await supabase
    .from("parallax_content")
    .select("*")
    .order("id", { ascending: true });

  if (error) {
    console.error("Error fetching parallax content:", error);
    return [];
  }

  return data;
};

export const fetchAndroidBuild = async () =>
  await supabase.rpc("android_build");

export const fetchIosBuild = async () => await supabase.rpc("ios_build");
